<template>
  <div class="login-background">
    <div class="login-box bg-white p-4 m-auto">
      <h1 class>VAVE MAESTRO</h1>
      <div class="my-4">
        <form>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input id="email" name="email" v-model="details.email" placeholder="Email Address" type="text" class="form-control" required="required" :class="{ 'is-invalid': errors.email }" />
            <div v-for="(error, k) in errors.email" :key="k" class="invalid-feedback" v-html="errors.email[k]"></div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input id="password" name="password" v-model="details.password" placeholder="Password" type="password" class="form-control" required="required" :class="{ 'is-invalid': errors.password }" />
            <div v-for="(error, k) in errors.password" :key="k" class="invalid-feedback" v-html="errors.password[k]"></div>
          </div>
          <div class="col">
            <button class="btn btn-primary" @click.prevent="login()">Log in</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      details: {
        email: "",
        password: "",
      },
      toast: null,
      errors: {},
    };
  },
  mounted() {
    if (this.$store.state.auth.notifySessionExpired) {
      this.$store.commit("notifySessionExpired", false);
      this.toast = this.$toast.warning(
        "Your session has expired. Please log in again.",
        {
          timeout: 0,
        }
      );
    }
    if (this.$store.state.auth.user) {
      // User is authenticated, let's make sure he is an admin
      this.$store.dispatch("checkLoggedUserIsAdmin").then(
        () => {
          // The user is an Admin, let's forward the request
          console.log("Admin is logged in: redirect him");
          this.$router.push("/");
        },
        () => {
          // User is logged in, but is not an Admin
          console.log(
            "User " +
              this.$store.state.auth.user.email +
              " is logged in, but is not an Admin"
          );
          this.$store.commit("setUser", null);
        }
      );
    }
  },
  methods: {
    login() {
      this.$toast.dismiss(this.toast);
      this.$store.dispatch("logout").then(() => {
        this.$store
          .dispatch("login", {
            email: this.details.email,
            password: this.details.password,
          })
          .then(
            () => {
              // User is authenticated, let's make sure he is an admin
              this.$store.dispatch("checkLoggedUserIsAdmin").then(
                () => {
                  // The user is an Admin, let's forward the request
                  this.$router.push("/");
                },
                () => {
                  // User is logged in, but is not an Admin
                  this.$store.commit("setUser", null);
                  this.errors = {
                    email: {
                      "*": "You are not an admin",
                    },
                  };
                }
              );
            },
            (e) => {
              if (e.response && e.response.data && e.response.data.errors) {
                this.errors = e.response.data.errors;
              }
            }
          )
          .catch((e) => {
            console.log(e);
          });
      });
    },
  },
};
</script>

<style style="scss" scoped>
.login-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #f1f5f9;
  .login-box {
    /* margin-top: 140px !important; */
    position: absolute;
    left: calc((100vw / 2) - 250px);
    width: 500px;
    min-height: 350px;
    top: calc((100vh / 2) - 300px);
    border-radius: 5px;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.3);
  }
}
</style>